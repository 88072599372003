import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContentBlocks from "../components/content-blocks"

export default ({ data }) => {
  const { title, bodyBgColor, bodyTextColor, bodyTitleColor, contentBlocks, seo } = data.contentfulModularPage

  return (
    <Layout
      bodyBgColor={bodyBgColor}
      bodyTextColor={bodyTextColor}
      bodyTitleColor={bodyTitleColor}
      pageTitle={seo && seo.seoTitle ? seo.seoTitle : title}
      pageDescription={seo && seo.description ? seo.description : null}
      pageImage={seo && seo.image ? seo.image.file.url : null}
      pageArticle={seo && seo.article ? seo.article : false}
    >
      <div className="container mt-32 md:mt-42 mb-6">
        <h1>{title}</h1>
      </div>

      {
        contentBlocks &&
        <ContentBlocks contentBlocks={contentBlocks} />
      }
    </Layout>
  )
}

export const query = graphql`
  query modularPageQuery($slug: String!) {
    contentfulModularPage(slug: {eq: $slug}) {
      seo {
        ... on Node {
            ... on ContentfulSeo {
            article
            seoTitle
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
      bodyBgColor
      bodyTextColor
      bodyTitleColor
      title
      contentBlocks {
        __typename
        ... on Node {
          ... on ContentfulTwoColumnMedia {
            backgroundColor
            id
            bottomMedia {
              svg {
                content
                dataURI
                absolutePath
                relativePath
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            marginBottom
            marginTop
            reverseOrderDesktop
            reverseOrderMobile
            standAloneMedia {
              svg {
                content
                dataURI
                absolutePath
                relativePath
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            topMedia {
              svg {
                content
                dataURI
                absolutePath
                relativePath
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulHeroImage {
            container
            marginTop
            marginBottom
            backgroundColor
            image {
              svg {
                  content
                  dataURI
                  absolutePath
                  relativePath
                }
                file {
                  contentType
                  url
                  fileName
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
                fluid(maxWidth: 2560, quality: 70) {
                  ...GatsbyContentfulFluid_withWebp
                }
            }
          }
          ... on ContentfulExternalVideo {
            backgroundColor
            marginTop
            marginBottom
            videoProvider
            videoId
            useContainer
          }
          ... on ContentfulHeroVideo {
            backgroundColor
            marginTop
            marginBottom
            container
            showPlayButton
            graphicOverlay {
              svg {
                content
                dataURI
                absolutePath
                relativePath
              }
              file {
                contentType
                url
                fileName
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            video {
              file {
                url
              }
            }
            poster {
              resize(width: 1920, height: 1080) {
                src
              }
            }
          }
          ... on ContentfulTwoColumn {
            marginTop
            marginBottom
            backgroundColor
            textColor
            media {
              file {
                contentType
                url
              }
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
            title
            reverseDesktop
            reverseMobile
          }
          ... on ContentfulPromotion {
            negativeMarginTop
            marginTop
            marginBottom
            title
            textCenter
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulPromotionSecondary {
            marginTop
            marginBottom
            title
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulOneColumn {
            marginTop
            marginBottom
            title
            largeText
            textCentered
            backgroundColor
            textColor
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulRelated {
            marginTop
            marginBottom
            viewTitle
            backgroundColor
            textColor
            relatedItems {
              ... on ContentfulProject {
                slug
                title
                featuredImage {
                  fluid(maxWidth: 1020, quality: 70) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                intro {
                  childMarkdownRemark {
                    excerpt(pruneLength: 200, truncate: true, format: PLAIN)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
